import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import SobreNosotros from "@/views/SobreNosotros.vue";
import DivisionesAutosView from "@/views/divisiones/DivisionesAutos.vue";
import DivisionesMotovehiculoView from "@/views/divisiones/DivisionesMotovehiculoView.vue";
import DivisionesMaquinariaAgricolaView from "@/views/divisiones/DivisionesMaquinariaAgricolaView.vue";
import SociosView from "@/views/servicios/SociosView.vue";
import AsesoriaLegal from "@/views/servicios/AsesoriaLegalView.vue";
import AsesoriaImpositiva from "@/views/servicios/AsesoriaImpositivaView.vue";
import AsesoriaRegistral from "@/views/servicios/AsesoriaRegistralView.vue";
import CapacitacionView from "@/views/servicios/CapacitacionView.vue";
import ContactoView from "@/views/Contacto.vue";
import PreciosView from "@/views/PreciosView.vue"; 
import ComisionDirectivaView from "@/views/comision-directiva/ComisionDirectivaView.vue"; 
import NovedadArticulo from "@/views/NovedadArticulo.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/sobre-nosotros",
    name: "sobre-nosotros",
    component: SobreNosotros,
  },
  {
    path: "/divisiones-autos",
    name: "divisiones-autos",
    component: DivisionesAutosView,
  },
  {
    path: "/divisiones-motovehiculo",
    name: "divisiones-motovehiculo",
    component: DivisionesMotovehiculoView,
  },
  {
    path: "/divisiones-maquinaria-agricola",
    name: "divisiones-maquinaria-agricola",
    component: DivisionesMaquinariaAgricolaView,
  },
  {
    path: "/servicios-socios",
    name: "socios",
    component: SociosView,
  },
  {
    path: "/servicios-asesoria-legal",
    name: "Asesoria legal",
    component: AsesoriaLegal,
  },
  {
    path: "/servicios-asesoria-impositiva",
    name: "Asesoria impositiva",
    component: AsesoriaImpositiva,
  },
  {
    path: "/servicios-asesoria-registral",
    name: "Asesoria registral",
    component: AsesoriaRegistral,
  },
  {
    path: "/servicios-capacitacion",
    name: "capacitacion",
    component: CapacitacionView,
  },
  {
    path: "/contacto",
    name: "contacto",
    component: ContactoView,
  },
  {
    path: "/guia-oficial-de-precios", 
    name: "precios",
    component: PreciosView,
  },
  {
    path: "/comision-directiva", 
    name: "comisionDirectiva",
    component: ComisionDirectivaView,
  },
  {
    path: "/novedades/:slugTitle/:id",
    name: "novedades",
    component: NovedadArticulo,
    props: true, 
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
