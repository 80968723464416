<template>
  <div class="col-12 text-right">
    <div v-for="(button, index) in buttons" :key="index" class="d-inline-block mr-3">
      <button 
        v-if="button.vehicle" 
        type="button" 
        class="btn btn-outline-primary btn-close-filter ml-2" 
        @click="resetFilters('vehicle', index)">
        {{ button.vehicle }} <i class="far fa-times-circle"></i>
      </button>
      <button 
        v-if="button.brand" 
        type="button" 
        class="btn btn-outline-primary btn-close-filter ml-2" 
        @click="resetFilters('brand', index)">
        {{ button.brand }} <i class="far fa-times-circle"></i>
      </button>
      <button 
        v-if="button.model" 
        type="button" 
        class="btn btn-outline-primary btn-close-filter ml-2" 
        @click="resetFilters('model', index)">
        {{ button.model }} <i class="far fa-times-circle"></i>
      </button>
      <button 
        v-if="button.version" 
        type="button" 
        class="btn btn-outline-primary btn-close-filter ml-2" 
        @click="resetFilters('version', index)">
        {{ button.version }} <i class="far fa-times-circle"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "VehicleFilterButton",
  props: {
    buttons: Array, 
  },
  methods: {
    resetFilters(type, index) {
      this.$emit('reset-filters', { type, index });
    },
  },
 
};
</script>

<style scoped>
.btn-close-filter {
  color: #2CBAD2;
  border-color: #2CBAD2;
  border-radius: 0;
  margin-bottom: 20px;
}
</style>
