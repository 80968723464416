<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h3 class="step-subtitle">SELECCIONA EL TIPO DE VEHÍCULO</h3>
      </div>
    </div>

    <div class="row grilla">
      <div
        v-for="vehicle in vehicles"
        :key="vehicle.id"
        class="col-sm-3"
      >
        <a @click.prevent="selectVehicle(vehicle)" class="link-selector">
          <img
            :src="vehicle.imgSrc"
            :alt="vehicle.name"
            style="height: 100px; width: auto; margin-bottom: 15px"
          /><br />
          {{ vehicle.name }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "VehicleTypeStep",
  setup(props, { emit }) {
    const currentStep = ref(2);

    const vehicles = ref([
      {
        name: "AUTOS",
        link: "AUTOS",
        imgSrc: require("@/assets/images/logo.png"),
        id: 1,
      },
      {
        name: "CAMIONES",
        link: "CAMIONES",
        imgSrc: require("@/assets/images/logo.png"),
        id: 3,
      },
      {
        name: "MAQUINARIA AGRÍCOLA",
        link: "MAQUINARIA AGRÍCOLA",
        imgSrc: require("@/assets/images/logo-maquinarias.png"),
        id: 4,
      },
      {
        name: "MOTOS",
        link: "MOTOS",
        imgSrc: require("@/assets/images/logo-ACARA-division-motos.png"),
        id: 2,
      },
    ]);

    const selectVehicle = (vehicle) => {
      emit('updateStepProgress', { step: currentStep.value });
      emit('vehicleSelected', { vehicle: vehicle });
    };

    return {
      vehicles,
      selectVehicle,
      currentStep,
    };
  },
};
</script>

<style scoped>
.step-subtitle {
  color: #2cbad2;
  margin-bottom: 40px;
  margin-top: 40px;
}

.grilla {
  display: flex;
  justify-content: space-around;
}

.link-selector {
  padding: 10px;
  display: block;
  text-align: center;
  color: #2cbad2;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}

.link-selector:hover {
  color: #00a0e1;
}
</style>
