<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="step-title">PASO {{ currentStep.step }} de 5</h2>
      </div>
      <VehicleFilterButton
        :buttons="currentStep.buttons"
        @reset-filters="resetFilters"
      />
    </div>

    <div class="row" v-if="currentStep.progress <= 100">
      <div class="col-12">
        <div class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            :style="{ width: currentStep.progress + '%' }"
            aria-valuenow="currentStep.progress"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
    </div>

    <VehicleTypeStep
      v-if="currentStep.step === 1"
      @updateStepProgress="updateStepProgress"
      @vehicleSelected="selectedVehicle"
    />
    <VehicleBrandStep
      v-if="currentStep.step === 2"
      :brands="brands"
      @updateStepProgress="updateStepProgress"
      @brandSelected="selectedBrand"
    />
    <VehicleModelStep
      v-if="currentStep.step === 3"
      :models="models"
      @updateStepProgress="updateStepProgress"
      @modelSelected="selectedModel"
    />
    <VehicleVersionStep
      v-if="currentStep.step === 4"
      :versions="versions"
      @updateStepProgress="updateStepProgress"
      @versionSelected="selectedVersion"
    />
    <VehiclePdfStep
      v-if="currentStep.step === 5"
      :vehiculeHtml="vehiculeHtml"
      @fetchPdfList="downloadPdf"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import {
  fetchBrandList,
  fetchModelList,
  fetchVersionList,
  fetchVehiculeData,
  fetchPdfList,
} from "@/services/api/price";
import VehicleTypeStep from "@/components/precios/steps/VehicleTypeStep.vue";
import VehicleBrandStep from "@/components/precios/steps/VehicleBrandStep.vue";
import VehicleModelStep from "@/components/precios/steps/VehicleModelStep.vue";
import VehicleVersionStep from "@/components/precios/steps/VehicleVersionStep.vue";
import VehiclePdfStep from "@/components/precios/steps/VehiclePdfStep.vue";
import VehicleFilterButton from "@/components/precios/VehicleFilterButton.vue";

export default {
  name: "StepPrecios",
  components: {
    VehicleTypeStep,
    VehicleBrandStep,
    VehicleModelStep,
    VehicleVersionStep,
    VehiclePdfStep,
    VehicleFilterButton,
  },
  setup() {
    const originalSteps = [
      { step: 1, progress: 25, buttons: [] },
      { step: 2, progress: 50, buttons: [] },
      { step: 3, progress: 75, buttons: [] },
      { step: 4, progress: 100, buttons: [] },
      { step: 5, progress: 101, buttons: [] },
    ];

    const steps = ref([...originalSteps]);

    const vehicleSelected = ref(null);
    const brandSelected = ref(null);
    const modelSelected = ref(null);
    const versionSelected = ref(null);

    const currentStep = ref({ ...steps.value[0] });
    const brands = ref([]);
    const models = ref([]);
    const versions = ref([]);
    const vehiculeHtml = ref("");

    const updateStepProgress = async ({ step }) => {
      const stepData = steps.value.find((s) => s.step === step);
      if (stepData) {
        currentStep.value = {
          ...stepData,
          buttons: [...currentStep.value.buttons],
        };
      }
    };

    const updateButtons = (key, value) => {
      const updatedButtons = [...currentStep.value.buttons];
      updatedButtons.push({ [key]: value });
      currentStep.value.buttons = updatedButtons;
    };

    const selectedVehicle = async ({ vehicle }) => {
      vehicleSelected.value = vehicle;
      updateButtons("vehicle", vehicle.name);
      await loadBrandList();
    };

    const selectedBrand = async ({ brand }) => {
      brandSelected.value = brand;
      updateButtons("brand", brand.name);
      await loadModelList();
    };

    const selectedModel = async ({ model }) => {
      modelSelected.value = model;
      updateButtons("model", model.name);
      await loadVersionList();
    };

    const selectedVersion = async ({ version }) => {
      versionSelected.value = version;
      updateButtons("version", version.name);
      await loadVehiculeData();
    };

    const loadBrandList = async () => {
      try {
        brands.value = await fetchBrandList(vehicleSelected.value.id);
      } catch (error) {
        console.error("Error al obtener la lista de marcas:", error);
      }
    };

    const loadModelList = async () => {
      try {
        const modelData = await fetchModelList(
          vehicleSelected.value.id,
          brandSelected.value.id
        );
        insertAllOption(modelData, "TODOS LOS MODELOS", models);
      } catch (error) {
        console.error("Error al obtener la lista de modelos:", error);
      }
    };

    const loadVersionList = async () => {
      try {
        if (isNaN(Number(modelSelected.value.id))) {
          vehiculeHtml.value = await fetchVehiculeData(
            vehicleSelected.value.id,
            brandSelected.value.id
          );
        } else {
          const versionData = await fetchVersionList(
            vehicleSelected.value.id,
            brandSelected.value.id,
            modelSelected.value.id
          );
          insertAllOption(versionData, "TODOS LAS VERSIONES", versions);
        }
      } catch (error) {
        console.error(
          "Error al obtener la lista de versiones o al descargar el PDF:",
          error
        );
      }
    };

    const loadVehiculeData = async () => {
      try {
        if (isNaN(Number(versionSelected.value.id))) {
          vehiculeHtml.value = await fetchVehiculeData(
            vehicleSelected.value.id,
            brandSelected.value.id,
            modelSelected.value.id
          );
        } else {
          vehiculeHtml.value = await fetchVehiculeData(
            vehicleSelected.value.id,
            brandSelected.value.id,
            modelSelected.value.id,
            versionSelected.value.id
          );
        }
      } catch (error) {
        console.error("Error al obtener los datos del vehículo:", error);
      }
    };

    const insertAllOption = (data, text, target) => {
      if (data && data.data) {
        data.data.unshift({ id: "t", name: text });
        target.value = data;
      }
    };

    const downloadPdf = async () => {
      try {
        const response = await fetchPdfList(
          vehicleSelected.value.id,
          brandSelected.value.id,
          modelSelected.value.id,
          versionSelected.value.id
        );

        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "precios_vehiculos.pdf");
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        link.remove();
      } catch (error) {
        console.error("Error al obtener el PDF:", error);
      }
    };

    const resetFilters = ({ type, index }) => {
      steps.value = JSON.parse(JSON.stringify(originalSteps));
      brands.value = [];

      if (index === 0) {
        currentStep.value.buttons = [];
        updateStepProgress({ step: 1 });
      }

      if (index === 1) {
        currentStep.value.buttons = [];
        updateStepProgress({ step: 2 });
        currentStep.value.buttons = currentStep.value.buttons.filter(
          (button) => !button[type]
        );
        selectedVehicle({ vehicle: vehicleSelected.value });
      }
      if (index === 2) {
        currentStep.value.buttons = currentStep.value.buttons.filter(
          (button) => button.vehicle
        );
        updateStepProgress({ step: 3 });
        currentStep.value.buttons = currentStep.value.buttons.filter(
          (button) => !button[type]
        );
        selectedBrand({ brand: brandSelected.value });
      }
      if (index === 3) {
        currentStep.value.buttons = currentStep.value.buttons.filter(
          (button) => button.vehicle || button.brand
        );
        updateStepProgress({ step: 4 });
        currentStep.value.buttons = currentStep.value.buttons.filter(
          (button) => !button[type]
        );
        selectedModel({ model: modelSelected.value });
      }
      if (index === 4) {
        currentStep.value.buttons = currentStep.value.buttons.filter(
          (button) => button.vehicle || button.brand
        );
        updateStepProgress({ step: 5 });
        currentStep.value.buttons = currentStep.value.buttons.filter(
          (button) => !button[type]
        );
        selectedVersion({ version: versionSelected.value });
      }
    };

    return {
      currentStep,
      brands,
      models,
      versions,
      vehiculeHtml,
      updateStepProgress,
      resetFilters,
      selectedVehicle,
      selectedBrand,
      selectedModel,
      selectedVersion,
      downloadPdf,
    };
  },
};
</script>

<style scoped>
.step-title {
  color: #2cbad2;
  font-weight: 800;
  text-align: center;
}

.progress {
  height: 20px;
  background-color: #e9ecef;
  border-radius: 10px;
}

.progress-bar {
  background-color: #00a0e1;
}

button {
  margin: 5px;
}
</style>
