<template>
  <div class="sobre-nosotros">
    <div v-if="loading" class="loading-indicator">
      <LoadingIndicator />
    </div>
    <div v-else>
      <div class="carousel-container">
        <CarouselComponent :slides="allViews.sliders" :positionTop="true" />
        <div v-if="allViews.sliders.length > 0"></div>
      </div>
      <PresentacionCapacitacionComponent />
      <ServicesComponent
        v-if="allViews.cards.length > 0"
        :novedades="allViews.cards"
      />
      <BlockComponent
        v-for="block in allViews.blocks"
        :key="block.id"
        :block="block"
        first-section-class="block-container"
      />
      <ButtonLinkComponent
        v-if="allViews.buttonLinks.length > 0"
        :buttonLinks="allViews.buttonLinks"
      />

      <NovedadComponent
        v-if="allViews.articles.length > 0"
        :articles="allViews.articles"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useImageLoader } from "@/composables/useImageLoader";
import { fetchViewByUrl } from "@/services/api/view.js";

import CarouselComponent from "@/components/carousel/CarouselComponent.vue";
import BlockComponent from "@/components/block/BlockComponent.vue";
import ButtonLinkComponent from "@/components/bottonLink/ButtonLinkComponent.vue";
import LoadingIndicator from "@/components/common/LoadingIndicator.vue";
import ServicesComponent from "@/components/servicios/Servicio.vue";
import NovedadComponent from "@/components/novedades/Novedad.vue";
import PresentacionCapacitacionComponent from "@/views/servicios/components/PresentacionCapacitacionComponent.vue";

const { loading, allViews, loadImages } = useImageLoader();

onMounted(() => {
  loadImages(fetchViewByUrl);
});
</script>

<style scoped>
.sobre-nosotros {
  margin: 0 auto;
  position: relative;
}

.carousel-container {
  position: relative;
}
</style>
