<template>
  <div class="sectionOtherContainer">
    <div class="row row-cols-1 row-cols-md-3 text-center">
      <div
        v-for="member in filteredCommitteeSection2"
        :key="member.id"
        class="col mb-4"
      >
        <div class="persona">
          <h5 class="cargoCustom">{{ member.position }}</h5>
          <h5 class="labelCustom">
            {{ member.label }} {{ member.lastname }}, {{ member.name }} ,
            {{ member.section }}
          </h5>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="row row-cols-1 row-cols-md-3 text-center">
      <div
        v-for="member in filteredCommitteeSection3"
        :key="member.id"
        class="col mb-4"
      >
        <div class="persona">
          <h5 class="cargoCustom">{{ member.position }}</h5>
          <h5 class="labelCustom">
            {{ member.label }} {{ member.lastname }}, {{ member.name }},
            {{ member.section }}
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "OrganizationalChartSectionOther",
  props: {
    committeeSection2: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    console.log(props.committeeSection2);
    const filteredCommitteeSection2 = computed(() =>
      props.committeeSection2.filter((member) => member.section === "2")
    );

    const filteredCommitteeSection3 = computed(() =>
      props.committeeSection2.filter((member) => member.section === "3")
    );

    return {
      filteredCommitteeSection2,
      filteredCommitteeSection3,
    };
  },
};
</script>

<style scoped>
.sectionOtherContainer {
  border-bottom: 1px solid #00a0e1;
  margin-bottom: 35px;
}

.persona {
  margin-bottom: 45px;
}

.labelCustom {
  color: #616161;
  font-weight: normal;
  margin-top: 5px;
}

.cargoCustom {
  color: #00a0e1;
  margin-top: 5px;
  font-weight: bold;
}
</style>
