<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h3 class="step-subtitle">SELECCIONE LAS VERSIONES DISPONIBLES</h3>
      </div>
    </div>

    <div v-if="isLoading" class="loading-indicator">
      Cargando versiones...
    </div>

    <div v-else class="row">
      <div class="col-12">
        <div
          v-for="version in versions.data"
          :key="version.id"
          class="version-item"
        >
          <a @click.prevent="selectVersion(version)" class="link-selector">
            {{ version.name }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";

export default {
  name: "VehicleVersionStep", 
  props: {
    versions: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const currentStep = ref(5);
    const isLoading = ref(true);

    watchEffect(() => {
      if (props.versions && props.versions.data && props.versions.data.length > 0) {
        isLoading.value = false;
      }
    });

    const selectVersion = (version) => {
      console.log(version)
      emit('updateStepProgress', { step: currentStep.value });
      emit('versionSelected', { version: version });
    };

    return {
      selectVersion,
      isLoading,
    };
  },
};
</script>

<style scoped>
.step-subtitle {
  color: #2cbad2;
  margin-bottom: 40px;
  margin-top: 40px;
}

.version-item {
  text-align: left; 
  border-bottom: solid 1px #D9D9D9;
  padding: 5px 10px;
}

.link-selector {
  color: #2CBAD2;
  font-weight: 400;
  font-size: 1.3rem;
  text-decoration: none;
  cursor: pointer;
}

.link-selector:hover {
  color: #00a0e1;
}

.loading-indicator {
  text-align: center;
  font-size: 18px;
  color: #2cbad2;
}
</style>
