<template>
  <div v-if="loading" class="loading-container">
    <div class="spinner"></div>
    <p>Cargando Comisión Directiva...</p>
  </div>
  <div v-else-if="error">
    <p>{{ error }}</p>
  </div>
  <div v-else-if="comisionDirectiva" id="comision-directiva">
    <ExecutiveCommitteeHeader />
    <OrganizationalChart :comisionDirectiva="comisionDirectiva" />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { fetchComisionDirectiva } from "@/services/api/comisionDirectiva.js";
import ExecutiveCommitteeHeader from "@/views/comision-directiva/components/ExecutiveCommitteeHeader.vue";
import OrganizationalChart from "@/views/comision-directiva/components/OrganizationalChart.vue";

export default {
  name: "ComisionDirectivaView",
  components: {
    ExecutiveCommitteeHeader,
    OrganizationalChart,
  },
  setup() {
    const comisionDirectiva = ref(null);
    const loading = ref(true);
    const error = ref(null);

    const loadComisionDirectiva = async () => {
      try {
        const response = await fetchComisionDirectiva();
        if (response) {
          comisionDirectiva.value = response;
        } else {
          error.value = "Comisión Directiva no encontrada";
        }
      } catch (err) {
        error.value = "Error al cargar la Comisión Directiva";
        console.error(err);
      } finally {
        loading.value = false;
      }
    };

    onMounted(loadComisionDirectiva);

    return {
      loading,
      error,
      comisionDirectiva,
    };
  },
};
</script>

<style scoped>
</style>
