export const navbarData = {
  items: [
    { name: "Socios", to: "/servicios-socios", dropdown: "Servicios" },
    { name: "Asesoría Legal", to: "/servicios-asesoria-legal", dropdown: "Servicios" },
    { name: "Asesoría Impositiva", to: "/servicios-asesoria-impositiva", dropdown: "Servicios" },
    { name: "Asesoría Registral", to: "/servicios-asesoria-registral", dropdown: "Servicios" },
    { name: "Capacitación", to: "/servicios-capacitacion", dropdown: "Servicios" },
    { name: "Estadística y Mercado", to: "https://www.siomaa.com/", external: true, dropdown: "Servicios" },
    { name: "Autos", to: "/divisiones-autos", dropdown: "Divisiones" },
    { name: "Motovehículos", to: "/divisiones-motovehiculo", dropdown: "Divisiones" },
    { name: "Maquinaria Agrícola", to: "/divisiones-maquinaria-agricola", dropdown: "Divisiones" },
  ],
};