<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h3 class="step-subtitle">Información del Vehículo</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div v-if="isLoading" class="loading-indicator">
          Cargando información del vehículo...
        </div>
        <div v-else>
          <div v-if="vehiculeHtml" v-html="vehiculeHtml"></div>
          <p v-else>No hay información disponible.</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <p class="download-pdf">
          <a @click.prevent="downloadPdf" target="_blank">Descargar PDF con todos los precios</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from 'vue';

export default {
  name: "VehiclePdfStep",
  props: {
    vehiculeHtml: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const isLoading = ref(true);

    watchEffect(() => {
      if (props.vehiculeHtml) {
        isLoading.value = false;
      }
    });

    const downloadPdf = () => {
      emit('fetchPdfList');
    };

    return {
      downloadPdf,
      isLoading,
    };
  },
};
</script>

<style scoped>
.step-subtitle {
  color: #2cbad2;
  margin-bottom: 40px;
  margin-top: 40px;
}

.download-pdf a {
  color: #007bff !important;
  text-decoration: none !important;
  cursor: pointer;
}

.download-pdf {
  margin-top: 20px;
}

.loading-indicator {
  text-align: center;
  font-size: 18px;
  color: #2cbad2;
}
</style>
