import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// Importar y registrar Swiper Custom Elements
import { register } from 'swiper/element/bundle';
register();

// Importa estilos CSS de Swiper
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import BootstrapVue3 from 'bootstrap-vue-3';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const app = createApp(App);
app.component('QuillEditor', QuillEditor)
app.use(router);
app.use(store);
app.use(BootstrapVue3);

app.mount('#app');
