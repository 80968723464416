<template>
  <section class="telepase">
    <div class="container h-100 d-flex justify-content-center align-items-end">
      <div class="row">
        <div class="botones">
          <template v-if="isLoggedIn">
            <a href="https://telepase.com.ar/register" target="_blank">
              <button class="btn btn-light">Registrar Cliente en AUSA</button>
            </a>
            <a href="https://telepase.com.ar/login" target="_blank">
              <button class="btn btn-light">Asociar Pase al Dominio</button>
            </a>
          </template>
          <template v-else>
            <a href="javascript:void(0);" data-toggle="modal" data-target="#ingresarModal">
              <button class="btn btn-light">Registrar Cliente en AUSA</button>
            </a>
            <a href="javascript:void(0);" data-toggle="modal" data-target="#ingresarModal">
              <button class="btn btn-light">Asociar Pase al Dominio</button>
            </a>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue';

const isLoggedIn = ref(false);
</script>

<style scoped>
.telepase {
  background: url('@/assets/images/telepase-acara-ausa.jpg') no-repeat center;
  background-size: cover;
  background-color: #435b39;
  height: 587px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.container {
  height: 100%; 
}

.botones {
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.btn {
  color: #435b39;
  font-weight: 800;
  font-size: 14px;
  text-transform: uppercase;
}


@media (max-width: 768px) {
  .telepase {
    height: 300px;
    background-size: cover;
  }

  .botones {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }

  .btn {
    font-size: 12px; 
    padding: 8px 20px; 
  }
}
</style>
