<template>
  <div v-if="loading" class="loading-container">
    <div class="spinner"></div>
    <p>Cargando artículo...</p>
  </div>
  <div v-else-if="error">
    <p>{{ error }}</p>
  </div>
  <div v-else-if="article">
    <section
      id="novedades"
      class="novedades-section"
      :style="{ backgroundImage: `url(${article.image})` }"
    >
      <div class="container">
        <div class="row justify-content-center align-items-center h-100">
          <div class="col-12">
            <h1 class="novedades-title">NOVEDADES</h1>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container content-articles">
        <h1>{{ article.title }}</h1>
        <p v-html="article.content"></p>
      </div>
    </section>

    <section class="container text-center mt-4">
      <a
        v-if="article.file"
        :href="article.file"
        target="_blank"
        class="download-link"
      >
        Descargar
      </a>
      <a
        v-else-if="article.link"
        :href="article.link"
        target="_blank"
        class="download-link"
      >
        Ver Más
      </a>
    </section>

    <section class="container text-center mt-4">
      <button @click="goBack" class="back-button">VOLVER</button>
    </section>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { fetchArticle } from "@/services/api/articles.js";

export default {
  name: "NovedadArticulo",
  setup() {
    const article = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const route = useRoute();
    const router = useRouter();

    const goBack = () => {
      router.go(-1);
    };

    const loadArticle = async () => {
      try {
        const response = await fetchArticle(route.params.id);
        if (response && response.id) {
          article.value = response;
        } else {
          error.value = "Artículo no encontrado";
        }
      } catch (err) {
        error.value = "Error al cargar el artículo";
        console.error(err);
      } finally {
        loading.value = false;
      }
    };

    onMounted(loadArticle);

    return {
      article,
      loading,
      error,
      goBack,
    };
  },
};
</script>

<style scoped>
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  text-align: center;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #001f5b;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.content-articles {
  padding: 60px 15px;
}

.content-articles h1 {
  text-align: center;
  color: #001f5b;
  font-weight: 600;
  margin-bottom: 100px;
  text-transform: uppercase;
}

.content-articles p {
  font-size: 1.2rem;
  line-height: 2rem;
}

.novedades-section {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 300px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
}

.novedades-title {
  color: #ffffff;
  font-size: 4rem;
  font-weight: 900;
  text-align: center;
  margin-top: 50px;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media (max-width: 768px) {
  .novedades-section {
    height: 250px;
  }

  .novedades-title {
    font-size: 2.5rem;
    margin-top: 0;
  }

  .content-articles h1 {
    font-size: 2rem;
    margin-bottom: 50px;
  }

  .content-articles p {
    font-size: 1.1rem;
    line-height: 1.8rem;
  }
}

@media (max-width: 480px) {
  .novedades-section {
    height: 200px;
  }

  .novedades-title {
    font-size: 2rem;
  }

  .content-articles h1 {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }

  .content-articles p {
    font-size: 1rem;
    line-height: 1.6rem;
  }
}

.download-link {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  font-size: 1.2rem;
  font-weight: bold;
  border: 1px solid #007bff;
  padding: 10px 20px;
  border-radius: 5px;
  display: inline-block;
}

.download-link:hover {
  background-color: #007bff;
  color: white;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.back-button {
  background: #001f5b;
  color: #fff;
  text-align: center;
  padding: 8px 30px;
  font-size: 1rem;
  margin-top: 5%;
  display: inline-block;
  text-decoration: none;
  border: none;
  cursor: pointer;
  margin-bottom: 35px;
}

.back-button:hover {
  background: #003a85;
}
</style>
