<template>
  <div class="item-slick">
    <div class="box-imagen">
      <div class="date">
        <div class="month">{{ abbreviatedMonth }}</div>
        <div class="day">{{ formattedDay }}</div>
      </div>
      <a :href="link">
        <img :src="imgSrc" alt="Novedad Image" />
      </a>
    </div>
    <div class="box-novedad">
      <h3>{{ categoryLabel }}: {{ title }}</h3>
      <p v-html="truncatedContent"></p>
      <a @click="viewMore" class="view-more-link">VER MÁS</a>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "vue";

export default defineComponent({
  name: "NovedadCard",
  props: {
    date: {
      type: String,
      required: true,
    },
    imgSrc: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    type: {
      type: Number, 
      required: true,
    },
  },
  setup(props, { emit }) {
    const categories = ref([
      { value: 1, label: "AUTOS" },
      { value: 2, label: "MOTOS" },
      { value: 3, label: "CAMIONES" },
      { value: 4, label: "MAQUINARIA AGRICOLA" },
      { value: 5, label: "SOCIOS" },
      { value: 6, label: "ASESORIA LEGAL" },
      { value: 7, label: "ASESORIA IMPOSITIVA" },
      { value: 8, label: "ASESORIA REGISTRAL" },
      { value: 9, label: "CAPACITACIONES" },
    ]);

    const abbreviatedMonth = computed(() => {
      const dateObj = new Date(props.date);
      const month = dateObj.toLocaleString('es-ES', { month: 'long' }).toUpperCase();
      return month.substring(0, 3);
    });

    const formattedDay = computed(() => {
      return props.date.split("-")[2];
    });

    const truncatedContent = computed(() => {
      const maxLength = 360;
      return props.content.length > maxLength
        ? props.content.substring(0, maxLength) + '...'
        : props.content;
    });

    const categoryLabel = computed(() => {
      const category = categories.value.find((cat) => cat.value === props.type);
      return category ? category.label : "Categoría desconocida";
    });

    const toSlug = (text) => {
      return text
        .toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-');
    };

    const viewMore = () => {
      const slugTitle = toSlug(props.title);
      emit("view-more", props.id, slugTitle);
    };

    return {
      abbreviatedMonth,
      formattedDay,
      truncatedContent,
      categoryLabel,
      viewMore,
      categories,
    };
  },
});
</script>

<style scoped>
.item-slick {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  width: calc(33.33% - 20px); 
  max-width: 370px; 
}

.box-imagen {
  position: relative;
}

.date {
  padding: 5px 13px;
  background-color: #0177bd;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  position: absolute;
  top: 18px;
  left: 20px;
  border-radius: 5px;
  text-align: center;
}

.month {
  border-bottom: 1px solid #FFF;
}

.box-imagen img {
  width: 100%;
  height: auto;
  display: block;
  height: 200px;
  object-fit: cover;
}

.box-novedad {
  background: #fff;
  padding: 40px;
  display: inline-block;
  min-height: 300px;
}

.box-novedad h3 {
  color: #06205c;
  font-weight: 400;
  font-size: 1.2rem;
  min-height: 70px;
}

.box-novedad p {
  color: #898989;
  text-align: left;
  margin: 30px 0;
  min-height: 240px;
}

.view-more-link {
  color: #06205c;
  font-weight: 400;
  float: right;
  text-decoration: none;
  font-size: 0.8rem;
  cursor: pointer;
}


@media (max-width: 768px) {
  .item-slick {
    width: calc(50% - 20px); 
    max-width: none;
  }
  .box-novedad {
    padding: 20px;
    min-height: 250px;
  }

  .box-novedad h3 {
    font-size: 1rem;
    min-height: 60px;
  }

  .box-novedad p {
    margin: 20px 0;
    min-height: 180px;
  }
}

@media (max-width: 480px) {
  .item-slick {
    width: calc(100% - 20px); 
    max-width: none;
  }
  .box-novedad {
    padding: 15px;
  }

  .box-novedad h3 {
    font-size: 0.9rem;
  }

  .box-novedad p {
    margin: 15px 0;
  }
}
</style>
