<template>
  <div>
    <div
      v-if="singleColumnSections && singleColumnSections.length > 0"
      class="first-section"
       :class="firstSectionClass"
      :style="{ backgroundColor: firstSectionBackgroundColor }"
    >
      <div class="container">
        <div
          v-for="section in singleColumnSections"
          :key="section.id"
          class="single-column"
          :class="{ 'content-text': section.content && !section.image }"
        >
          <div
            v-if="section.content && !section.image"
            v-html="section.content"
          ></div>
          <div
            v-else-if="section.image && !section.content"
            class="background-image"
            :style="{ backgroundImage: `url(${section.image})` }"
          ></div>
        </div>
      </div>
    </div>

    <div class="second-section">
      <div class="container">
        <div class="row">
          <div
            v-for="section in twoColumnSections"
            :key="section.id"
            class="col-md-6 col-sm-12"
          >
            <div
              v-if="section.content && !section.image"
              v-html="section.content"
              class="section-column"
              :style="{
                backgroundColor:
                  section.background_color !== '#ffffff'
                    ? section.background_color
                    : null,
              }"
            ></div>
            <div
              v-else-if="section.image && !section.content"
              class="background-image"
              :style="{ backgroundImage: `url(${section.image})` }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "BlockComponent",
  props: {
    block: {
      type: Object,
      required: true,
    },
    firstSectionClass: {
      type: String,
      default: "class-default" 
    },
  },

  setup(props) {
    console.log(props.block);

    const singleColumnSections = computed(() =>
      props.block.sections
        .filter((section) => section.type === 1)
        .sort((a, b) => a.order - b.order)
    );

    const twoColumnSections = computed(() =>
      props.block.sections
        .filter((section) => section.type === 2)
        .sort((a, b) => a.order - b.order)
    );

    // Calcula el color de fondo de la primera sección
    const firstSectionBackgroundColor = computed(() => {
      const firstSection = singleColumnSections.value[0];
      return firstSection && firstSection.background_color
        ? firstSection.background_color
        : "#ffffff";
    });

    return {
      singleColumnSections,
      twoColumnSections,
      firstSectionBackgroundColor,
    };
  },
};
</script>

<style>
.first-section {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  padding: 15px 0;
  background-color: #ffffff; /* Valor por defecto */
}

.first-section .container {
  max-width: 1320px;
  margin: auto;
}

@media (max-width: 768px) {
  .first-section {
    padding: 50px 20px;
  }
}

.second-section {
  background: #f9f9f9;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}

@media (max-width: 768px) {
  .second-section {
    padding: 50px 20px;
  }
}

/* .single-column {
  margin-bottom: 20px;
}
 */
@media (max-width: 768px) {
  .single-column {
    margin-bottom: 0px!important;
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
}

.background-image {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .background-image {
    background-size: cover;
    min-height: 200px;
  }
}

.content-text {
  color: #757575;
  font-size: 20px;
  line-height: 30px;
}

@media (max-width: 768px) {
  .content-text {
    font-size: 18px;
    line-height: 28px;
  }
}

.section-column {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: 768px) {
  .section-column {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.single-column {
  margin-bottom: 0px!important;
  margin-left: 9rem;
  margin-right: 9rem;
}

.ql-align-center {
    margin-bottom: 0px!important;
  text-align: center;
}

.ql-align-right {

  text-align: right;
}

.ql-align-left {
  text-align: left;
}

ol {
  list-style-type: disc;
}

.ql-align-center{
  text-align: center;
}


.first-section{
  padding: 40px;
}

</style>
