<template>
  <section class="servicios">
    <div class="container">
      <div class="row justify-content-between">
        <CardComponent
          v-for="(service, index) in filteredAndSortedNovedades"
          :key="index"
          :imageSrc="getImage(service.image)"
          :iconSrc="getImage(service.icon)"
          :title="service.title"
          :description="service.content"
          :linkHref="service.link"
          :pdfFile="service.file"
          :buttonColor="service.color"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, computed } from "vue";
import CardComponent from "./CardComponent.vue";

export default defineComponent({
  name: "ServicesComponent",
  components: {
    CardComponent,
  },
  props: {
    services: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    // Filtrado y orden de servicios
    const filteredAndSortedNovedades = computed(() => {
      return props.services
        .filter((service) => service.status === 2)
        .sort((a, b) => a.order - b.order);
    });

    // Método para obtener la ruta de la imagen
    const getImage = (imageName) => {
      if (!imageName) return "";

      if (imageName.startsWith('http://') || imageName.startsWith('https://')) {
        return imageName;
      }

      try {
        return require(`@/assets/images/${imageName}`);
      } catch (e) {
        console.error("Image not found:", imageName);
        return "";
      }
    };

    return {
      filteredAndSortedNovedades,
      getImage,
    };
  },
});
</script>

<style scoped>
.servicios {
  background: url(@/assets/images/bg_servicios.png) no-repeat;
  background-size: cover;
  padding: 50px 0;
}

.row {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 20px;
}

.row > * {
  flex: 1;
  min-width: 250px;
}

@media (max-width: 768px) {
  .row {
    flex-wrap: wrap;
  }

  .row > * {
    flex: 1 1 100%;
    max-width: 100%;
    min-width: auto;
  }
}
</style>
