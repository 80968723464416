<template>
  <header class="header">
    <div class="container">
      <div class="header-top">
        <div class="header-left">
          <LogoComponent @logo-clicked="resetActivePage" />
        </div>

        <div class="header-right">
          <LoginButtonComponent v-if="!isMobile" />
        </div>
      </div>

      <div class="header-bottom">
        <NavbarComponent
          :active-page="activePage"
          @update-active-page="setActivePage"
          :is-mobile="isMobile"
        />
      </div>
    </div>
  </header>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import LogoComponent from "@/components/header/LogoComponent.vue";
import NavbarComponent from "@/components/header/navbar/NavbarComponent.vue";
import LoginButtonComponent from "@/components/header/LoginButtonComponent.vue";

export default {
  name: "HeaderComponent",
  components: {
    LogoComponent,
    NavbarComponent,
    LoginButtonComponent,
  },
  setup() {
    const activePage = ref("");
    const isMobile = ref(false);

    const setActivePage = (page) => {
      activePage.value = page;
    };

    const resetActivePage = () => {
      activePage.value = "";
    };

    const checkIsMobile = () => {
      isMobile.value = window.innerWidth <= 768;
    };

    onMounted(() => {
      checkIsMobile();
      window.addEventListener("resize", checkIsMobile);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", checkIsMobile);
    });

    return {
      activePage,
      isMobile,
      setActivePage,
      resetActivePage,
    };
  },
};
</script>

<style scoped>
.header {
  background-color: #fff;
  padding: 0px 20px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left {
  display: flex;
  flex: 1;
  justify-content: flex-start;
}

.header-right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.header-bottom {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .header {
    padding: 10px;
  }
}
</style>
