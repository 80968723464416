<template>
  <section class="asociate">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h3>SIENDO SOCIO DE ACARA</h3>
          <p>Podés asociarte a <strong>ACA</strong> con 6 cuotas bonificadas</p>
          <p>
            <img
              src="https://www.aca.org.ar/wp-content/uploads/2017/09/logo-aca-new.png"
              alt="Logo ACA"
            />
          </p>
          <template v-if="isLoggedIn">
            <a href="/landing_aca.html" target="_blank">
              <button class="btn btn-light">Asociarme</button>
            </a>
          </template>
          <template v-else>
            <a href="javascript:void(0);" data-toggle="modal" data-target="#ingresarModal">
              <button class="btn btn-light">Asociarme</button>
            </a>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue';

const isLoggedIn = ref(false);
</script>

<style scoped>
.asociate {
  background: url('@/assets/images/bg_asociate.jpg') no-repeat center;
  background-size: cover;
  height: 325px;
  padding: 90px 0;
}

.asociate h3 {
  color: #ffffff;
  font-size: 27px;
  text-transform: uppercase;
  font-weight: 800;
}

.asociate p {
  color: #ffffff;
  font-size: 18px;
}

.asociate .btn {
  color: #0377bd;
  font-weight: 800;
  font-size: 14px;
  text-transform: uppercase;
}
</style>
